import React from 'react';
import { BsX } from 'react-icons/bs';

import styles from './styles.module.css';

export type TagColor =
  | 'red'
  | 'pink'
  | 'yellow'
  | 'green'
  | 'blue'
  | 'purple'
  | 'kitsuPurple'
  | 'grey';

export type TagProps = {
  children: string;
  color: TagColor;
  onRemove?: React.MouseEventHandler<HTMLElement>;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

export default function Tag({ children, color, onRemove, onClick }: TagProps) {
  return (
    <span
      className={[
        styles.tag,
        styles[color],
        onClick ? styles.clickable : null,
        onRemove ? styles.removable : null,
      ].join(' ')}>
      <span className={styles.text}>{children}</span>
      {onRemove ? (
        <span className={styles.removeButton} onClick={onRemove}>
          <BsX />
        </span>
      ) : null}
    </span>
  );
}
